import React, { useEffect, useState } from 'react';
import "./NodeTable.scss"
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';
import CreateHubPanel from '../CreateHubPanel/CreateHubPanel';
import HubLocationDetails from '../HubLocationDetails/HubLocationDetails';
import CreateLocationPanel from '../CreateLocationPanel/CreateLocationPanel';
import AddMissionFile from '../AddMissionFile/AddMissionFile';


const NodeTable = ({ userCookie, adminDesignation, adminLocation, setOpenModal, setSelectedTab, allLocations, SetLoadingVisibility, handleNotifyAlert }) => {
    const [addNodeForm, setAddNodeForm] = useState(false)
    // const [detailsPopUp, setDetailsPopUp] = useState(false)
    // const [selectedHub, setSelectedHub] = useState()
    const [allNodes, setAllNodes] = useState([]);
    const [hubs, setHubs] = useState([])
    const [duplicateNode, setDuplicateNode] = useState([])
    const [showDetails, setShowDetails] = useState(false)
    const [locationData, setLocationData] = useState()

    const handleAddNodeButton = () => {
        setAddNodeForm(true);
    }
    useEffect(() => {
        axios({
            url: backEndIP + "/location/get_location",
            // url: backEndIP + "/user/login",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then((hubResp) => {
            //console.log(hubResp.data.data)
            setAllNodes(hubResp.data.data.sort((a, b) => (Number(a.tag_name.slice(1)) > Number(b.tag_name.slice(1)) ? 1 : -1)));
            setDuplicateNode(hubResp.data.data.sort((a, b) => (Number(a.tag_name.slice(1)) > Number(b.tag_name.slice(1)) ? 1 : -1)));
            //console.log(allHub)
        }).catch((err) => {
            console.log(err);
        })

        axios({
            url: backEndIP + "/hub/gethub",
            // url: backEndIP + "/user/login",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then((hubAvailable) => {
            setHubs(hubAvailable.data.data);
        })
            .catch((err) => {
                console.log(err);
            })
    }, [showDetails])
    //const duplicate = [...allNodes]
    const handelFilterByHub = (e) => {
        // console.log(e.target.value)
        if (e.target.value === "All") {
            setAllNodes(duplicateNode)
        } else {
            const filteredData = []
            duplicateNode.forEach((element) => {
                // console.log(element)
                if (element.relatedHubs === e.target.value) {
                    return filteredData.push(element)
                } else {
                    return null
                }
            })
            // console.log(filteredData)
            setAllNodes(filteredData)
        }
    }
    // const sortRows = (Data, sortBy = 0, key) => {

    // }
    // console.log("ManagersLocId", adminLocation)
    // console.log("ManagersLoc", allLocations)
    return (
        <div className='MainNodeTab'>
            <div className='NodeTabContent-container'>
                <div className='NodeTabContent-container__filter'>
                    <div className='NodeTabContent-container__filter__title' >Choose Hub : </div>
                   {adminDesignation !== "Hub Manager" ? <select className='NodeTabContent-container__filter__options' onChange={(e) => { handelFilterByHub(e) }}>
                        <option>All</option>
                        {hubs.map((hub) => {
                            return <option key={`hubsOptions${hub._id}`} value={hub._id}>{hub.location_name}</option>
                        })}
                    </select>: <div className='NodeTabContent-container__filter__options'>{allLocations.hubNames[adminLocation]}</div>}
                   {adminDesignation === "Hub Manager" || adminDesignation === "System User"? <div className='NodeTabContent-container__filter__AddNewNodeButton'>
                    <button className='NodeTabContent-container__filter__AddNewNodeButton__Btn' onClick={() => { handleAddNodeButton() }}>Add Node</button></div>:null}
                </div>

                <div className='NodeTabContent-datacontainer' >
                    <div className='NodeTabContent-container__index'>
                        <div className='NodeTabContent-container__index__SNo NodeTabContent-container__index__term'>
                            S.No
                        </div>
                        <div className='NodeTabContent-container__index__locName NodeTabContent-container__index__term'>
                            Location Name
                        </div>
                        <div className='NodeTabContent-container__index__latitude NodeTabContent-container__index__term' >
                            Latitude
                        </div>
                        <div className='NodeTabContent-container__index__longitude NodeTabContent-container__index__term'>
                            Longitude
                        </div>
                        <div className='NodeTabContent-container__index__state NodeTabContent-container__index__term'>
                            State
                        </div>
                        <div className='NodeTabContent-container__index__address NodeTabContent-container__index__term' >
                            Address
                        </div>
                        <div className='NodeTabContent-container__index__pinCode NodeTabContent-container__index__term'>
                            Pin Code
                        </div>
                        <div className='NodeTabContent-container__index__relatedHub NodeTabContent-container__index__term'>
                            Hub
                        </div>
                        <div className='NodeTabContent-container__index__tagName NodeTabContent-container__index__term'>
                            Node Name
                        </div>
                      {adminDesignation === "Hub Manager" || adminDesignation === "System User" ? <div className='NodeTabContent-container__index__addMission NodeTabContent-container__index__term'>
                            Add Mission
                        </div>: <div className='NodeTabContent-container__index__addMission NodeTabContent-container__index__term'>
                            Get Details
                        </div>}
                        {/* <div className='ModelTabContent-container__index__dateTime ModelTabContent-container__index__term' >
                        Date & Time 
                    </div> */}

                    </div>
                    {allNodes.length <= 0 ? <div className='NodeTabContent-datacontainer'>There is no Node Connected to this hub</div> : <>
                        {allNodes.map((node, i) => {
                            // console.log("Hubs=", allLocations.hubNames[node.relatedHubs])
                            return <div key={node._id} className='NodeTabContent-datacontainer__row' >
                                <div className='NodeTabContent-datacontainer__row__SNo NodeTabContent-datacontainer__row__term'>
                                    {i + 1}
                                </div>
                                <div className='NodeTabContent-datacontainer__row__locName NodeTabContent-datacontainer__row__term'>
                                    {node.location_name}
                                </div>
                                <div className='NodeTabContent-datacontainer__row__latitude NodeTabContent-datacontainer__row__term'>
                                    {node.latitude}
                                </div>
                                <div className='NodeTabContent-datacontainer__row__longitude NodeTabContent-datacontainer__row__term'>
                                    {node.longitude}
                                </div>
                                <div className='NodeTabContent-datacontainer__row__state NodeTabContent-datacontainer__row__term'>
                                    {node.state}
                                </div>
                                <div className='NodeTabContent-datacontainer__row__address NodeTabContent-datacontainer__row__term'>
                                    {node.address}
                                </div>
                                <div className='NodeTabContent-datacontainer__row__pinCode NodeTabContent-datacontainer__row__term'>
                                    {node.pin_code}
                                </div>
                                <div className='NodeTabContent-datacontainer__row__relatedHub NodeTabContent-datacontainer__row__term'>
                                    {allLocations.hubNames[node.relatedHubs]}
                                </div>
                                <div className='NodeTabContent-datacontainer__row__tagName NodeTabContent-datacontainer__row__term'>
                                    {node.tag_name}
                                </div>
                                <div className='NodeTabContent-datacontainer__row__addMission NodeTabContent-datacontainer__row__term'>
                                    {adminDesignation === "Hub Manager" || adminDesignation === "System User"?
                                    <button className='NodeTabContent-datacontainer__row__addMission__addMissBtn' 
                                    onClick={() => { setShowDetails(true); setLocationData(node) }}>
                                    Add mission</button>:  <button className='NodeTabContent-datacontainer__row__addMission__addMissBtn' 
                                    onClick={() => { setShowDetails(true); setLocationData(node) }}>
                                    Get Details</button>}
                                </div>
                            </div>
                        })}
                    </>}

                </div>
            </div>
            {addNodeForm ? <CreateLocationPanel
                setFormPopup={setAddNodeForm}
                setAllNode={setAllNodes}
                setDuplicateNode={setDuplicateNode}
                duplicateNode={duplicateNode}
                adminDesignation={adminDesignation}
                allNodes={allNodes}
                allLocations={allLocations}
                userCookie={userCookie}
                SetLoadingVisibility={SetLoadingVisibility}
                handleNotifyAlert={handleNotifyAlert} /> : null}
            {showDetails && <AddMissionFile
                setDetailsPopUp={() => { setSelectedTab("Nodes"); setShowDetails(false) }}
                adminDesignation={adminDesignation}
                userCookie={userCookie}
                locationDetails={locationData}
                SetLoadingVisibility={SetLoadingVisibility}
                handleNotifyAlert={handleNotifyAlert} 
                locations={allLocations}/>}
        </div>
    )
}

export default NodeTable