import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import "./DroneDetailpopUp.scss";
import { backEndIP } from "../MyConfiguration";
import axios from "axios";
//import AllDronesTab from "../AllDrones/AllDrones";

function DroneDetailPage({ setDetailsPopUp, setAllDrones, allDrones, droneDetailsPopup, userCookie, handleNotifyAlert, SetLoadingVisibility, locations }) {
  const modalRef = useRef();
  const fileInputElement = useRef();
  const fileNameTextBox = useRef();
  const [valueTobeUpdate, setValueTobeUpdate] = useState({
    "internal_id": droneDetailsPopup.internal_id.toString(),
    "drone_phase": droneDetailsPopup.drone_phase,
    "hub_location": droneDetailsPopup.hub_location,
    "max_range": droneDetailsPopup.max_range,
    //"current_location": ""
  })
  const [hubLocation, setHubLocation] = useState([])
  const [officeLocation, setOfficeLocation] = useState([])
  const closeModal = e => {
    if (modalRef.current === e.target) {
      setDetailsPopUp();
    }
  };
  useEffect(() => {
    const getLocation = async () => {
      axios({
        url: backEndIP + "/hub/gethub",
        // url: backEndIP + "/user/login",
        method: "GET",
        headers: {
          authorization: userCookie.token
        },
      }).then((resp) => {
        //console.log(resp.data)
        setHubLocation(resp.data.data);
        //console.log(hubLocation)
      }).catch((err) => {
        console.log(err);
      })
      const officeLocation = await axios({
        url: backEndIP + "/office/getOffice",
        // url: backEndIP + "/user/login",
        method: "GET",
        headers: {
          authorization: userCookie.token
        },
      }).catch((err) => {
        console.log(err);
      })
      // console.log(officeLocation.data)
      setOfficeLocation(officeLocation.data.data)
    }
    // console.log(employeesResp.data.data);
    getLocation()
  }, [])

  const handleDeployment = () => {
    if (valueTobeUpdate.hub_location !== "") {
      if (droneDetailsPopup.hub_location !== valueTobeUpdate.hub_location) {
        SetLoadingVisibility(true)
        axios({
          url: backEndIP + "/drone/deploy_drone",
          method: "POST",
          headers: {
            authorization: userCookie.token
          },
          data: valueTobeUpdate,
        }).then((resp) => {
          SetLoadingVisibility(false)
          setDetailsPopUp();
          handleNotifyAlert(
            "notify",
            `Drone Deployed Successfully at ${locations[valueTobeUpdate.hub_location]}`,
            "success",
            () => { }
          );
          console.log("/drone/deploy_drone", resp.data.data);
          setAllDrones(resp.data.data)

        }).catch((err) => {
          console.log(err);
          SetLoadingVisibility(false);
          setDetailsPopUp();
          handleNotifyAlert(
            "alert",
            `${err.response.message}`,
            "failed",
            () => { }
          );
          // if (err.response.data.message) window.alert(err.response.message);
          // else window.alert("some error occurred");
        })
      } else {
        SetLoadingVisibility(false);
        setDetailsPopUp(false);
        handleNotifyAlert(
          "alert",
          `Drone is already present at ${locations[droneDetailsPopup.hub_location]}`,
          "failed",
          () => { }
        );
      }
    } else {
      SetLoadingVisibility(false);
      setDetailsPopUp(false);
      handleNotifyAlert(
        "alert",
        `Please select the Hub`,
        "failed",
        () => { }
      );
      //window.alert("Please select the Hub")
    }
  }

  // const locations = {
  //   "63932c7ab1cab628335ae969": "Jengjal",
  //   "63930f82865ec3abed90bc1b": "Mendipathar PHC",
  //   "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
  //   "639311e2865ec3abed90bc23": "Nongalbibra PHC",
  //   "6393126c865ec3abed90bc27": "Shallang PHC",
  //   "639312ff865ec3abed90bc2b": "Samanda PHC",
  //   "63bd5b3ccafee0c35772f8f1": "Office",
  // }
  const list = {
    "last_maintenance_date": "Date",
    "manufacturing_date": "Date",
    "current_location": "Location",
    "hub_location": "Location",
    "flight_hours": "time",
    "drone_password": "Password",
    "current_flight_id": "Flight Id",
    "__v": "Password"
  }
  const CompileContent = (data) => {
    if (list[data] === "Date") {
      return dayjs(droneDetailsPopup[data]).format("YYYY-MM-DD HH:mm").toString();
    }
    else if (list[data] === "Location") {
      //  console.log("droneTobeManage[data]", droneDetailsPopup[data])
      // console.log("locations", locations, locations[droneDetailsPopup[data]])
      return locations[droneDetailsPopup[data]]
    }
    else if (list[data] === "time") {
      return (droneDetailsPopup[data].hours + ":" + droneDetailsPopup[data].minutes + ":" + droneDetailsPopup[data].seconds);
    } else if (list[data] === "Password") {
      return
    } else if (list[data] === "Flight Id") {
      if (droneDetailsPopup[data] === "") {
        return "NA"
      } else {
        return droneDetailsPopup[data]
      }
    }
    return droneDetailsPopup[data];
  }
  const phase = ["Testing", "Commissioned"]
  const [drone, setDrone] = useState([])
  const drone_Data = {
    "_id": "Drone ID",
    "internal_id": "Drone Internal Id",
    "model_no": "Model No",
    "payload_capacity": "Payload Capacity(Kg)",
    "drone_password": "Drone Password",
    "weight": "Drone Weight",
    "length": "Drone Length",
    "width": "Drone Width",
    "height": "Drone Height",
    "max_range": "Maximum Range(km)",
    "flight_hours": "Flight Hours(HH:mm:ss)",
    "last_maintenance_date": "Last Maintenance Date",
    "manufacturing_date": "Manufacturing Date",
    "drone_phase": "Drone Phase",
    "drone_version": "Drone Version",
    "pilot_id1": "Pilot 1",
    "pilot_id2": "Pilot 2",
    "hub_location": "Drone Deployed at Hub",
    "current_location": "Current Location",
    "current_flight_id": "Current Flight Id",
    "__v": "Drone Password"
  }
  useEffect(() => {
    const ownFlight = [];
    const keys = Object.keys(droneDetailsPopup);
    keys.forEach(key => {
      ownFlight.push([drone_Data[key], CompileContent(key)])
    });
    ownFlight.pop()
    setDrone(ownFlight)


  }, [droneDetailsPopup])
  // console.log(hubLocation)
  // console.log(internalId)
  // console.log(officeLocation)

  const handleConfigFileChange = () => {
    const reader = new FileReader();
    //console.log(fileInputElement.current.files.length)
    if (fileInputElement.current.files.length) {
      fileNameTextBox.current.value = fileInputElement.current.files[0].name;
      //if(fileInputElement.current.files[0].name)
      console.log(fileInputElement.current.files[0])
      if (fileInputElement.current.files[0].name.split(".")[1] === "txt") {
        reader.onload = () => {
          console.log("reader.result", reader.result)
          fileInputElement.current.value = "";
        }
        reader.readAsText(fileInputElement.current.files[0])
      } else {
        window.alert("wrong extension file selected. Only .txt files are accepted")
        fileInputElement.current.value = "";
      }

    }

  };
  return (
    <div onClick={closeModal} ref={modalRef} className="DroneDetailsPopup-modalBackground">
      <div className="DroneDetailsPopup-modalContainer" >
        <div className="DroneDetailsPopup-modalContainer__heading" >Drone Deployment Form</div>
        <div className="DroneDetailsPopup-modalContainer__flexBox">
          <div className="DroneDetailsPopup-modalContainer__flexBox__leftSide">
            {drone.sort().map((item) => {
              {
                if (item[0] === "Drone Password") {
                  return null
                } else {
                  return (<div className="DroneDetailsPopup-modalContainer__flexBox__item">
                    <div className="DroneDetailsPopup-modalContainer__flexBox__item__title">{item[0]}</div>
                    :
                    <div className="DroneDetailsPopup-modalContainer__flexBox__item__value"> {item[1]}</div>
                  </div>)
                }
              }
            })}
          </div>
          <div className="DroneDetailsPopup-modalContainer__flexBox__rightSide">
            {/* <div className="DroneDetailsPopup-modalContainer__flexBox__field">
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__label">Version update</div>
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__colon"> : </div>
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__options">
                <select className="DroneDetailsPopup-modalContainer__flexBox__field__options__select"
                  onChange={(e) => {
                    setValueTobeUpdate({ ...valueTobeUpdate, ["drone_phase"]: e.target.value })
                  }}
                >
                  {phase.map((item) => {
                    if (valueTobeUpdate.drone_phase === item) {
                      return <option value={item} selected > {item}</option>
                    }

                    return <option value={item} > {item}</option>
                  })}
                </select>
              </div>
            </div> */}
            <div className="DroneDetailsPopup-modalContainer__flexBox__field">
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__label">Maximum Range(Km)</div>
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__colon"> : </div>
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__input">
                <input
                  className="DroneDetailsPopup-modalContainer__flexBox__field__input__addValue"
                  type="number"
                  value={valueTobeUpdate.max_range}
                  onChange={(e) => { setValueTobeUpdate({ ...valueTobeUpdate, ["max_range"]: e.target.value }) }}
                ></input>
              </div>
            </div>
            <div className="DroneDetailsPopup-modalContainer__flexBox__field">
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__label">Select Drone Phase</div>
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__colon"> : </div>
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__options">
                <select className="DroneDetailsPopup-modalContainer__flexBox__field__options__select"
                  onChange={(e) => {
                    setValueTobeUpdate({ ...valueTobeUpdate, ["drone_phase"]: e.target.value })
                  }}
                >
                  {/* <option>{valueTobeUpdate.drone_phase}</option> */}
                  {phase.map((item) => {
                    if (valueTobeUpdate.drone_phase === item) {
                      return <option value={item} selected > {item}</option>
                    }

                    return <option value={item} > {item}</option>
                  })}
                </select>
              </div>
            </div>
            <div className="DroneDetailsPopup-modalContainer__flexBox__field">
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__label">Select Hub</div>
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__colon"> : </div>
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__options">
                <select className="DroneDetailsPopup-modalContainer__flexBox__field__options__select"
                  onChange={(e) => {
                    setValueTobeUpdate({ ...valueTobeUpdate, ["hub_location"]: e.target.value })
                    //setValueTobeUpdate({ ...valueTobeUpdate, ["current_location"]: e.target.value })
                  }}
                >
                  {/* <option></option> */}
                  {hubLocation.map((item) => {
                    // console.log("HubLocation=", item, valueTobeUpdate.hub_location, valueTobeUpdate.hub_location === item)
                    if (valueTobeUpdate.hub_location === item._id) {
                      return <option value={item} selected > {item.location_name}</option>
                    }
                    return <option value={item._id}>{item.location_name}</option>
                  })}
                  {officeLocation.map((item) => {
                    if (valueTobeUpdate.hub_location === item._id) {
                      return <option value={item} selected > {item.tag_name}</option>
                    }
                    return <option value={item._id}>{item.tag_name}</option>
                  })}
                </select>
              </div>
            </div>
            <div className="DroneDetailsPopup-modalContainer__flexBox__field">
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__label">Config File</div>
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__colon"> : </div>
              <div className="DroneDetailsPopup-modalContainer__flexBox__field__input">
                <input type="file" hidden ref={fileInputElement}
                  onChange={handleConfigFileChange} />
                <input
                  className="DroneDetailsPopup-modalContainer__flexBox__field__input__addValue"
                  type="text"
                  readOnly
                  ref={fileNameTextBox}
                  onClick={() => { fileInputElement.current.click() }}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <div className="DroneDetailsPopup-modalContainer__button" >
          <button className="DroneDetailsPopup-modalContainer__button__Cancel" onClick={() => { modalRef.current.click(); }}>Cancel</button>
          <button className="DroneDetailsPopup-modalContainer__button__OkayBtn" onClick={() => { handleDeployment() }}>OK</button>
        </div>
      </div>
    </div>
  );
}

export default DroneDetailPage;
